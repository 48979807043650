<template>
	<v-fade-transition hide-on-leave>
		<template v-if="loading || loadingParent">
			<v-skeleton-loader key="transitionCard" type="card" class="rounded-xl" />
		</template>
		<template v-else>
			<v-card key="transitionEnded" class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
				<v-card-title>
					<span>{{ $t('applications.applications') }}</span>
				</v-card-title>
				<v-card-text>
					<v-list dense v-if="user.applications">
						<v-list-item v-for="application in user.applications" :key="application.id" :to="{ name: 'Offer', params: { id: application.offer.id } }">
							<v-list-item-title>{{ application.offer.title }}</v-list-item-title>
						</v-list-item>
					</v-list>
					<template v-else>
						{{ $t('applications.noApplicationsYet') }}
					</template>
				</v-card-text>
			</v-card>
		</template>
	</v-fade-transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'UserApplications',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters({
			user: 'users/user'
		})
	}
}
</script>
